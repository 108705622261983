.ant-form-item {
  margin-bottom: 22px;
}

.ant-form-item label {
  margin-bottom: 0px;
}

.ant-form-explain {
  line-height: 1.2;
  position: absolute;
  white-space: nowrap;
  padding-right: 1em;
}

.ant-form-item-bottom-0 {
  margin-bottom: 0px;
}

.ant-form-item-bottom-0.ant-form-item-with-help {
  margin-bottom: 18px !important;
}

.ant-form-explain-error .ant-form-explain {
  color: #f5222d;
}

.ant-form-item-label .ant-select-selection {
  border: none;
  box-shadow: none;
  height: unset;
}

.ant-form-item-children textarea {
  margin: 4px 0px;
}

.ant-form-item-label .ant-select-selection__rendered {
  margin: 0px;
  line-height: unset;
}

.ant-select-selection__placeholder {
  font-weight: 600;
}

.ant-form-item-label .ant-select-selection .anticon {
  color: gray;
}

.ant-form-item-checkbox-group .ant-form-item-control {
  line-height: unset;
}

.ant-form-item-checkbox-group .ant-checkbox-group {
  line-height: 24px;
}

.ant-input-search .ant-input-group-addon {
  padding: 0 11px;
  border: 1px solid #d9d9d9;
}

.ant-input-search .ant-input-group-addon:last-child {
  border-left: 0;
}

.ant-form-textarea-readonly {
  background: #fafafa;
  margin-top: 4px;
  padding: 2px 10px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  line-height: 24px;
  min-height: 32px;
}

.ant-form-textarea-readonly > div:first-child {
  margin-top: 1px;
}

.ant-form-item-phone-line .ant-form-item {
  margin-bottom: 0px;
}

.ant-form-item-button-line {
  padding-bottom: 0px;
}

.ant-form-item-button-line .ant-form-item-children > button:not(:last-child),
.ant-form-item-button-line .ant-form-item-children > .ant-btn-group:not(:last-child),
.ant-form-item-button-line .ant-form-item-children > a:not(:last-child) {
  margin-right: 1rem !important;
}

.ant-form-item-button-center {
  text-align: center;
}

.ant-form-item-button-center .ant-form-item-children > button:not(:last-child),
.ant-form-item-button-center .ant-form-item-children > .ant-btn-group:not(:last-child),
.ant-form-item-button-center .ant-form-item-children > a:not(:last-child) {
  margin-right: 0.5rem !important;
}

.ant-form-item-button-icon {
  white-space: nowrap;
}

.ant-form-item-button-icon > button:not(:last-child),
.ant-form-item-button-icon > a:not(:last-child) {
  margin-right: 0.25rem !important;
}

.ant-form-item-no-height .ant-form-item-control {
  line-height: unset;
}

.ant-form-item-inline-block {
  display: inline-block;
}

.ant-form-item-content-flex {
  display: flex;
  flex-wrap: wrap;
}

.ant-form-item-content-flex > .ant-form-item:first-child {
  margin-right: 30px;
}

.ant-radio-group .ant-radio-wrapper {
  display: block;
}

.ant-form-item-radio-group-inline {
  .ant-form-item-control {
    line-height: 24px;
  }

  .ant-radio-wrapper {
    display: inline-block;
  }
}

.ant-form-item-middle-icon {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin: -6px;
}

.ant-form-item-label {
  line-height: unset;
  padding: 0px;
  display: block;
  text-align: left;
}

.ant-form-item-label label:after {
  display: none;
}

.ant-form-item-required:before {
  display: none;
}

.ant-form-item-required:after {
  content: '✻' !important;
  display: inline-block !important;
  font-weight: normal;
  color: orangered;
  position: absolute !important;
  right: -22px;
}

.ant-form-item-full-width {
  width: 100%;
}

.ant-form-item-full-width .ant-form-item-label > label {
  width: 100%;
}

.ant-upload-list-item-thumbnail {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-upload-list-item-name {
  color: #20a8d8;
  font-weight: 600;
  cursor: pointer;
}

.ant-upload-list-item-name:hover {
  color: #167495;
}

.ant-upload-list-item .ant-progress-inner {
  background: orangered;
}

.form-upload-readonly .ant-upload-list-item .anticon-close {
  display: none;
}

.form-upload-responsive {
  display: flex;
  flex-direction: column;
}

.form-upload-responsive .ant-upload-list {
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
}

.form-upload-responsive .ant-upload-list-item {
  width: 100%;
}

@media screen and (min-width: 576px) {
  .modal-medium .form-upload-responsive .ant-upload-list-item,
  .ticket-upload .form-upload-responsive .ant-upload-list-item {
    width: 47.5%;
  }

  .modal-medium .form-upload-responsive .ant-upload-list-item:nth-child(2n + 0),
  .ticket-upload .form-upload-responsive .ant-upload-list-item:nth-child(2n + 0) {
    margin-right: 0%;
  }

  .modal-medium .form-upload-responsive .ant-upload-list-item:nth-child(2n + 1),
  .ticket-upload .form-upload-responsive .ant-upload-list-item:nth-child(2n + 1) {
    margin-right: 5%;
  }
}

@media screen and (min-width: 768px) {
  .form-upload-responsive .ant-upload-list-item {
    width: 47.5%;
  }

  .form-upload-responsive .ant-upload-list-item:nth-child(2n + 0) {
    margin-right: 0%;
  }

  .form-upload-responsive .ant-upload-list-item:nth-child(2n + 1) {
    margin-right: 5%;
  }
}

@media screen and (min-width: 1200px) {
  .form-upload-responsive .ant-upload-list-item {
    width: 31%;
  }

  .form-upload-responsive .ant-upload-list-item:nth-child(3n + 0) {
    margin-right: 0%;
  }

  .form-upload-responsive .ant-upload-list-item:nth-child(3n + 1) {
    margin-right: 3.5%;
  }

  .form-upload-responsive .ant-upload-list-item:nth-child(3n + 2) {
    margin-right: 3.5%;
  }
}

@media screen and (min-width: 1600px) {
  .form-upload-responsive .ant-upload-list-item {
    width: 23.5%;
  }

  .form-upload-responsive .ant-upload-list-item:nth-child(4n + 0) {
    margin-right: 0%;
  }

  .form-upload-responsive .ant-upload-list-item:nth-child(4n + 1) {
    margin-right: 2%;
  }

  .form-upload-responsive .ant-upload-list-item:nth-child(4n + 2) {
    margin-right: 2%;
  }

  .form-upload-responsive .ant-upload-list-item:nth-child(4n + 3) {
    margin-right: 2%;
  }
}
