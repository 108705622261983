fieldset {
  border: 1px solid;
  padding: 10px;
}

legend {
  width: auto;
  margin: 0px 20px;
  padding: 0px 5px 5px 5px;
  font-size: 1.3em;
  color: crimson;
}

button {
  cursor: pointer;
}

* {
  word-break: normal !important;
}

a {
  text-decoration: none !important;
}

strong {
  font-weight: 600;
}

.card-header strong {
  font-size: 1.07em;
  color: darkolivegreen;
}

.text-required:after {
  content: '✻';
  font-weight: normal;
  color: orangered;
  position: absolute;
  margin-left: 3px;
}

.text-seemore:after {
  content: '...';
  margin-right: 6px;
}

.text-seeless:after {
  content: '';
  margin-right: 6px;
}

.card.line-card {
  height: calc(100% - 1.5rem);
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.text-highlight {
  color: deeppink;
}

.text-danger-dark {
  color: orangered !important;
}

.text-danger-light {
  color: orange !important;
}

.text-success-dark {
  color: darkgreen;
}

.text-success-light {
  color: darkseagreen;
}

.text-label {
  color: #212121 !important;
}

.text-info {
  color: #0d5c8c !important;
}

.text-dark-blue {
  color: darkblue;
}

.text-note {
  color: cadetblue;
}

.text-grey {
  color: #777;
}

.text-bold {
  font-weight: 600;
}

.text-slim {
  font-weight: 500;
}

.text-small {
  font-size: 13px;
}

.text-bigger {
  font-weight: 600;
  font-size: 1.07em;
}

.text-link {
  color: #20a8d8;
  cursor: pointer;
}

.text-link:hover {
  color: #167495;
}

.text-link-orange {
  color: hotpink;
  cursor: pointer;
}

.text-link-orange:hover {
  color: red;
}

// VENDOR
.ant-select-dropdown-menu-item-disabled {
  color: white !important;
}

.nav {
  display: flex;
  flex-wrap: nowrap;
}

.ticket-title-line span {
  margin-right: 6px;
}

.icon-receive-channel {
  padding: 4px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}

.background-expired-green {
  background: forestgreen;
}

.background-expired-orange {
  background: orange;
}

.background-expired-orangered {
  background: orangered;
}

.background-highlight {
  background: bisque;
}

.ticket-priority-high {
  padding: 2px 6px;
  border-radius: 4px;
  background: yellow;
}

.ticket-expired-green {
  padding: 2px 6px;
  border-radius: 4px;
  background: forestgreen;
  color: #fff;
}

.ticket-expired-orange {
  padding: 2px 6px;
  border-radius: 4px;
  background: orange;
  color: #fff;
}

.ticket-expired-orangered {
  padding: 2px 6px;
  border-radius: 4px;
  background: orangered;
  color: #fff;
}

// CUSTOMIZE
.white-space-normal {
  white-space: normal !important;
}

.white-space-nowrap {
  white-space: nowrap !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-text {
  cursor: text;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.line-height-40 {
  line-height: 40px !important;
}

.line-height-32 {
  line-height: 32px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.column-position {
  text-align: right !important;
  font-size: 13px;
  font-weight: 600;
  color: brown;
}

.badge-blue {
  border-radius: 9px;
  background: royalblue;
  color: white;
}

.background-green-light {
  background: #ccff99;
}

.background-blue-light {
  background: #99ddff;
}

.background-orange-light {
  background: peachpuff;
}

// FRAME BORDERED
.frame-bordered {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  padding: 8px;
}

.frame-bordered .ant-form-item-label label {
  color: brown;
}

.frame-bordered .ant-form-item-control {
  line-height: 24px;
}

// HEADER CALLING
.header-calling {
  text-align: right;
}

.header-calling i {
  margin: 0px;
  /* */
}

.header-calling .icon-calling {
  font-size: 16px;
  margin-right: 0.5rem;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.header-calling .line-actions {
  margin-left: 0.5rem;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.header-calling .icon-action {
  margin: 0rem 0.25rem;
  cursor: pointer;
}

.header-calling .icon-action.ticket {
  color: #ec971f;
}

.header-calling .icon-action.callnote {
  color: #1890ff;
}

.header-calling .branch-calling {
  margin-right: 1.25rem;
  color: #1890ff !important;
}

.header-calling .branch-calling:after {
  position: absolute;
  content: '...';
}

.header-calling .phone-calling {
  padding: 0px 8px;
  color: orangered !important;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

// USER SETTING
.user-setting-toggle {
  display: flex;
  align-items: flex-end;
}

.user-setting-toggle strong {
  margin-right: 4px;
}

.user-setting-toggle i {
  margin-bottom: 2px;
}

// AGENT STATUS
.agent-status-toggle strong {
  margin-right: 4px;
}

.agent-status-toggle {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-right: 12px;
  min-width: 180px;
}

.agent-status .dropdown-menu {
  left: -10px !important;
  min-width: unset;
}

.agent-status .dropdown-item {
  border-bottom: none;
  min-width: unset;
  padding: 10px;
  padding-right: 20px;
}

.agent-status .ant-badge-not-a-wrapper {
  vertical-align: baseline;
}

.agent-status .ant-badge-status-dot {
  width: 10px;
  height: 10px;
}

// NOTIFICATION
.notification .dropdown-header {
  min-width: 320px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}

.notification .isread:hover {
  background: #d9e7ff;
}

.notification .unread {
  background: #edf2fa;
}

.notification .unread:hover {
  background: #d9e7ff;
}

.notification .item-when {
  font-size: 12px;
  font-weight: 600;
  color: #949494;
}

.header-question-line {
  height: 21px;
}
