.ant-modal-mask,
.ant-modal-wrap {
  z-index: 1020;
}

.ant-notification {
  z-index: 1021;
}

.ant-modal-header {
  background: #f0f3f5;
  border-bottom: 1px solid #c8ced3;
}

.ant-modal-title {
  color: darkolivegreen;
  font-size: 1.07em;
  font-weight: 600;
}

.ant-modal-footer {
  display: none;
}

.ant-modal-content .ant-modal-close {
  outline: none;
}

.modal-footer .ant-modal-footer {
  display: block;
}

.modal-calling .ant-modal-header {
  background: #ccff99;
}

.modal-calling .ant-modal-header i {
  margin-right: 0.5em;
}

.modal-calling .header-calling {
  text-align: left;
}

.modal-extension .ant-spin-container {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: auto auto auto auto;
}

.modal-extension .ant-spin-container:after,
.modal-extension .ant-spin-container:before {
  content: none;
}

.modal-extension .ant-spin-nested-loading {
  min-height: 40px;
}

.modal-extension .button-extension {
  padding: 0px 15px !important;
}

.modal-extension .button-extension .anticon-loading {
  position: absolute;
  right: 15px;
}

@media screen and (min-width: 768px) {
  .modal-medium {
    width: 75% !important;
    max-width: 900px;
  }
}

@media screen and (min-width: 768px) {
  .modal-stretch {
    width: 75% !important;
  }
}
