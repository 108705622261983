@import 'buttons';
@import 'timeline';
@import 'form';
@import 'input';
@import 'modal';

.ant-form-explain-holder {
  display: none;
}

.ant-table-fixed-header .ant-table-scroll .ant-table-header {
  margin-right: -100px;
}

.ant-table-column-has-actions {
  background-clip: padding-box !important;
}

.ant-table-body {
  overflow-y: auto !important;
}

.ant-table th,
.ant-table td {
  padding: 8px !important;
}

.ant-table .ant-input-group-wrapper {
  top: 0;
}

.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0px;
}

.ant-table-thead > tr > th {
  font-weight: 600;
  white-space: nowrap;
  border-radius: 0px !important;
  background: #eee;
}

.ant-table-thead-pivot > th {
  font-weight: 600;
  background: #eee;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}

.ant-table-placeholder {
  font-weight: 600;
}

.ant-table .ant-table-thead .header-input-search {
  padding-left: 0px !important;
}

.ant-table .header-input-search input {
  border-radius: 0px;
  border-width: 1px 0px;
  padding-left: 8px;
}

.ant-table-sub-row {
  margin: -9px;
}

.ant-table-sub-row .ant-table-tbody > tr > td:last-child,
.ant-table-sub-row .ant-table-thead > tr > th:last-child {
  border-right: 0px;
}

.ant-calendar-my-select > a,
.ant-calendar-footer-btn > a {
  font-weight: 600 !important;
}

.ant-calendar-footer-btn {
  text-transform: capitalize;
}

.ant-calendar-footer-btn .ant-calendar-ok-btn {
  text-transform: uppercase;
}

.ant-calendar-today-btn,
.ant-calendar-time-picker-btn {
  color: #20a8d8 !important;
}

.ant-calendar-today-btn:hover,
.ant-calendar-time-picker-btn:hover {
  color: #167495 !important;
}

.ant-calendar-ok-btn {
  color: white !important;
}

.ant-calendar-picker-icon {
  font-size: 12px;
}

.ant-calendar-picker-clear,
.ant-calendar-picker-icon {
  margin-top: -8px;
}

.ant-select-arrow {
  margin-top: -7px;
}

.ant-form-item label {
  font-weight: 600;
  cursor: pointer;
}

.ant-tooltip-content {
  font-weight: 600;
}

.ant-form-explain {
  font-weight: 600;
  font-size: 13px;
}

.ant-btn-group {
  display: inline-flex;
}

.ant-btn-group .ant-select-selection {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.ant-btn {
  line-height: 1;
  font-weight: 600;
}

button svg {
  vertical-align: -1.5px;
}

.ant-tag {
  margin: 0px;
  font-weight: 600;
  cursor: pointer;
}

.ant-calendar-range-quick-selector > .ant-tag {
  margin-right: 8px;
}

.ant-notification-notice {
  background: #ffffaa;
}

.ant-input-affix-wrapper .ant-input-suffix {
  right: 12px !important;
}

.ant-input-search.ant-input-search-enter-button > .ant-input-suffix {
  right: 0px !important;
}

.button-tag-red {
  color: #f5222d;
  background: #fff1f0;
}

.button-tag-green {
  color: #52c41a;
  background: #f6ffed;
}

.button-tag-blue {
  color: #6a90a6;
  background: #fffffa;
}

.button-tag-gold {
  color: #faad14;
  background: #fffbe6;
}

.button-tag-volcano {
  color: #fa541c;
  background: #fff2e8;
}

.button-tag-orange {
  color: #fa8c16;
  background: #fff7e6;
}

.input-ant-tag-green .ant-input-group-addon:first-child,
.input-ant-tag-green .ant-select-selection.ant-select-selection--single {
  font-weight: 600;
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}

.input-ant-tag-red .ant-input-group-addon:first-child,
.input-ant-tag-red .ant-select-selection.ant-select-selection--single {
  font-weight: 600;
  color: #f5222d;
  background: #fff1f0;
  border-color: #ffa39e;
}

.input-ant-tag-orange .ant-input-group-addon:first-child,
.input-ant-tag-orange .ant-select-selection.ant-select-selection--single {
  font-weight: 600;
  color: #fa8c16;
  background: #fff7e6;
  border-color: #ffd591;
}

.input-ant-tag-cyan .ant-input-group-addon:first-child,
.input-ant-tag-cyan .ant-select-selection.ant-select-selection--single {
  font-weight: 600;
  color: #13c2c2;
  background: #e6fffb;
  border-color: #87e8de;
}

.input-ant-tag-geekblue .ant-input-group-addon:first-child,
.input-ant-tag-geekblue .ant-select-selection.ant-select-selection--single {
  font-weight: 600;
  color: #2f54eb;
  background: #f0f5ff;
  border-color: #adc6ff;
}

.input-ant-tag-purple .ant-input-group-addon:first-child,
.input-ant-tag-purple .ant-select-selection.ant-select-selection--single {
  font-weight: 600;
  color: #722ed1;
  background: #f9f0ff;
  border-color: #d3adf7;
}

.input-ant-tag-aliceblue .ant-input-group-addon:first-child,
.input-ant-tag-aliceblue .ant-select-selection.ant-select-selection--single {
  font-weight: 600;
  background: aliceblue;
}

.input-ant-tag-azure .ant-input {
  font-weight: 600;
  background: azure;
}

.input-ant-tag .ant-input-group-addon:first-child,
.input-ant-tag .ant-select-selection.ant-select-selection--single {
  font-weight: 600;
}

.ant-tabs-nav .ant-tabs-tab {
  font-weight: 600 !important;
  padding: 8px 16px;
  border: 1px solid #e8e8e8;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.anticon {
  vertical-align: 0.125em;
}

.ant-radio-group .ant-radio-button-wrapper {
  border-radius: 0px !important;
  white-space: nowrap;
}

.ant-radio-group .ant-radio-inner {
  width: 16px;
  height: 16px;
}

.ant-btn {
  display: inline-flex;
  align-items: center;
}

.ant-radio-button-wrapper .ant-radio-button-input {
  cursor: pointer;
}

.highlight.ant-radio-button-wrapper:hover {
  color: #ff6644;
}

.highlight.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  border-color: #ff6644;
  box-shadow: -1px 0 0 0 #ff6644;
  color: #ff6644;
}

.highlight.ant-radio-button-wrapper.ant-radio-button-wrapper-checked:hover {
  border-color: #ff8866;
  box-shadow: -1px 0 0 0 #ff8866;
  color: #ff8866;
}
