.sidebar .nav-link.active {
  background: #207171;
}

.sidebar .nav-dropdown.open {
  background: unset;
}

.sidebar .nav-dropdown-toggle {
  color: khaki !important;
}

.sidebar .nav-title {
  padding-bottom: 0px;
  color: cyan;
}

.sidebar .nav-item {
  font-weight: 600;
}

.nav-tabs .nav-item {
  font-weight: 600;
}

.breadcrumb .breadcrumb-item {
  font-weight: 600;
}

.app-header {
  flex-wrap: nowrap;
}

.app-header button {
  outline: none;
}

.app-header .nav-item button {
  display: flex;
  align-items: center;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  cursor: not-allowed;
}

.app-header .navbar-nav .dropdown-menu-right {
  right: auto;
}

.app-header .navbar-nav i {
  font-size: 16px;
  color: #666;
}

.app-header {
  border-bottom: none;
}

.header-divider {
  z-index: 1;
  position: fixed;
  width: 100%;
  height: 1px;
  background: #c8ced3;
}

.ant-layout-loading {
  background-image: url('https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg') !important;
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;
}
