.ant-calendar-picker {
  width: 100% !important;
}

.ant-calendar-range-picker-input,
.ant-calendar-picker-input {
  font-weight: 600;
}

.ant-select {
  width: 100%;
}

.ant-input-number {
  width: 100%;
}

.ant-input-number .ant-input-number-handler-wrap {
  display: none;
}

.ant-input-number-addon {
  height: 40px;
  display: flex;
  align-items: center;
}

.ant-input-number-addon .ant-input-number {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

.ant-input-number-addon .addon-before {
  background: #fafafa;
  padding: 0 11px;
  font-weight: 600;
  height: 32px;
  line-height: 31px;

  border-radius: 4px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;

  border: 1px solid #d9d9d9;
  border-right: 0;
}

.ant-checkbox-block .ant-checkbox-wrapper {
  display: block;
  margin-left: 0px !important;
  line-height: 24px;
}

.ant-select-dropdown-menu-item {
  height: 32px;
}

.input-table-filter .ant-input {
  padding-left: 4px;
}

.input-table-filter .ant-select-selection__rendered {
  margin-left: 4px;
}

.input-table-filter .ant-select-dropdown-menu-item {
  padding-left: 5px;
}

.ant-table .row-nopadding > td {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.input-readonly .ant-input-group-wrapper {
  padding-bottom: 2px;
}

.input-readonly .ant-input-group-addon {
  white-space: normal;
  line-height: 1.2;
  padding-top: 5px;
  padding-bottom: 5px;
}

.input-search .ant-input-group-wrapper {
  padding-bottom: 2px;
}

.input-readonly .ant-input-group-addon:first-child {
  width: 100%;
  text-align: unset;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.input-readonly .ant-input {
  width: 0px;
  margin: 0px;
  padding: 0px;
  border: none;
}

.input-readonly .ant-input-group-addon:last-child {
  display: none;
}

.input-readonly.addon-after .ant-input-group-addon:last-child {
  display: table-cell;
}

.input-readonly.addon-after .ant-input-group-addon:first-child {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.input-align-right,
.input-align-right .ant-input {
  text-align: right;
}

.ant-radio-group .ant-radio-button-wrapper {
  transition-duration: 0ms;
}

.ant-pagination-options .ant-pagination-options-size-changer .ant-select-selection-selected-value,
.ant-pagination-options .ant-select-dropdown-menu .ant-select-dropdown-menu-item {
  text-transform: capitalize;
}

.ant-pagination-options .ant-pagination-options-size-changer .ant-select-selection-selected-value {
  text-align: right;
  padding-right: 0px;
  font-weight: 600;
  color: #20a8d8;
}

.ant-pagination-options .ant-pagination-options-size-changer .ant-select-arrow {
  display: none;
}

.ant-pagination-options .ant-select-dropdown-menu-item {
  text-align: right;
}

.ant-dropdown-disabled .ant-select-dropdown-menu-item-disabled {
  display: none;
}
