.ticket-timeline,
.conversation-timeline {
  .ant-timeline {
    margin-left: 12px;
  }

  .ant-timeline .ant-timeline-item:not(:first-child) .ant-timeline-item-head {
    display: none;
  }

  .ant-timeline .anticon {
    font-size: 24px;
  }

  .ant-timeline .ant-timeline-item-content {
    margin-left: 24px;
  }

  .ant-timeline .ticket-icon {
    position: absolute;
    left: -31px;
    z-index: 1;
    cursor: pointer;
  }

  .ant-timeline .ticket-when {
    font-size: 13px;
    font-weight: 600;
  }

  .ant-timeline .ticket-note {
    display: flex;
    justify-content: space-between;
  }

  .ant-timeline .ticket-note > div {
    width: 100%;
  }

  .ant-timeline .ticket-note > span {
    min-width: 20px;
  }

  .ant-timeline .ticket-note .ticket-note-header {
    text-align: center;
    font-weight: 600;
  }

  .ant-timeline .ticket-note .ticket-note-lines {
    padding: 2px 6px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    height: calc(100% - 1.3rem);
  }

  .ant-timeline .ticket-who {
    font-weight: 600;
  }
}

.ticket-timeline {
  @media screen and (min-width: 576px) {
    .ant-timeline .ticket-header {
      display: flex;
      justify-content: space-between;
    }
  }

  @media screen and (min-width: 768px) {
    .ant-timeline {
      margin-left: 150px;
    }

    .ant-timeline .ticket-when {
      position: absolute;
      left: -200px;
      width: 200px;
      text-align: right;
      padding-right: 38px;
    }

    .ant-timeline .ticket-header,
    .ant-timeline .ticket-note,
    .ant-timeline .ticket-upload {
      max-width: 500px;
    }
  }

  @media screen and (min-width: 992px) {
    .ant-timeline {
      margin-left: 200px;
    }

    .ant-timeline .ticket-header,
    .ant-timeline .ticket-note,
    .ant-timeline .ticket-upload {
      max-width: 600px;
    }
  }

  @media screen and (min-width: 1200px) {
    .ant-timeline {
      margin-left: 250px;
    }

    .ant-timeline .ticket-header,
    .ant-timeline .ticket-note,
    .ant-timeline .ticket-upload {
      max-width: 700px;
    }
  }

  @media screen and (min-width: 1600px) {
    .ant-timeline {
      margin-left: 300px;
    }

    .ant-timeline .ticket-header,
    .ant-timeline .ticket-note,
    .ant-timeline .ticket-upload {
      max-width: 800px;
    }
  }
}

.conversation-timeline {
  @media screen and (min-width: 768px) {
    .ant-timeline .ticket-header {
      display: flex;
      justify-content: space-between;
    }
  }

  @media screen and (min-width: 992px) {
    .ant-timeline {
      margin-left: 150px;
    }

    .ant-timeline .ticket-when {
      position: absolute;
      left: -200px;
      width: 200px;
      text-align: right;
      padding-right: 38px;
    }
  }
}
